
import { Options, Vue } from 'vue-class-component';
import { CustomerClient, ExhibitionLocationClient } from '@/services/services';
import { StorageServices } from '@/services/StorageServices';
import RegisterLoginModal from '@/components/modals/registerLoginModal.vue';
import GetDayOfWeek from '@/components/getDayOfWeek.vue';
import * as OM from '@/model';
import store from '@/store';
import OpeningTimesModal from '@/components/modals/openingTimesModal.vue';
import SwiperGalleryModal from '@/components/modals/swiperGalleryModal.vue';
import { getStructureMap } from '@/utils';

@Options({
    components: {
        GetDayOfWeek
    }
})
export default class Location extends Vue {

    loggedRole: string = "";
    location: OM.LocationDetailVM = new OM.LocationDetailVM();

    showMore: boolean = false;
    needLessButton: boolean = false;

    created() {
        this.loggedRole = StorageServices.getLoggedUser().role;   

        var locationId = this.$route.params.locationIdentifier.toString();
        ExhibitionLocationClient.getLocationDetail(locationId)
        .then(x => {
            this.location = x;

            this.$nextTick(() => {
                let locationDescriptionEl = <HTMLElement>this.$refs.locationDescription;
                let windowTreshold = window.innerHeight * 19 / 100;
                if(parseFloat(getComputedStyle(locationDescriptionEl).height) > windowTreshold){
                    this.showMore = true;
                    this.needLessButton = true;
                }
            })
        })
    }

    toggleSavedMusem() {
        if(!this.loggedRole || this.loggedRole == "anonymous") {
            var text = this.$localizationService.getLocalizedValue("app_LoginRequiredForSaveMuseum", "Login is required to save a museum in your account.")
            this.$opModal.show(RegisterLoginModal, {
                text: text
            })
        }
        else {
            if(!this.location.isSavedByUser) {
                CustomerClient.saveLocation(this.location.identifier, false)
                .then(x => {})
            }
            else {
                CustomerClient.removeLocation(this.location.identifier, false)
                .then(x => {})
            }

            this.location.isSavedByUser = !this.location.isSavedByUser;
        }
    }

    toggleNewsletterSubscription() {
        if(!this.loggedRole || this.loggedRole == "anonymous") {
            var text = this.$localizationService.getLocalizedValue("app_LoginRequiredForNewsletterSubscription", "Login is required to subscribe newsletter.")
            this.$opModal.show(RegisterLoginModal, {
                text: text
            })
        }
        else {
            ExhibitionLocationClient.toggleNewsletter(this.location.identifier, false)
            .then(x => {})

            this.location.isUserSubscribedToNewsletter = !this.location.isUserSubscribedToNewsletter;
        }
    }

    navigateExhibition(exhibitionIdentifier: string) {
        if(!this.loggedRole) {
            this.$router.push("/welcome/" +  exhibitionIdentifier);
        }
        else {
            store.state.exhibition.identifier = exhibitionIdentifier;

            var exhibition = new OM.GetExhibitionVm();
            exhibition.identifier = exhibitionIdentifier;
            StorageServices.setExhibition(exhibition);

            this.$router.push("/exhibition/" +  exhibitionIdentifier);
        }
    }

    openOpeningTimesModal() {
        this.$opModal.show(OpeningTimesModal, {
            openingTimes: this.location.openingTime
        })
    }

    get hasMap() {
        var maps = getStructureMap(this.location.exhibitionLocationSections);
        return maps.length > 0;
    }
    showLocationMap() {
        var maps = getStructureMap(this.location.exhibitionLocationSections);
        this.$opModal.show(SwiperGalleryModal, {
            gallery: [maps]
        })
    }

    backToMainExhibition() {
        var mainExhibitionId = StorageServices.getMainExhibition();
        this.$router.replace("/exhibition/" +  mainExhibitionId);
    }

}
